<template>
  <div class="head">
    <div class="head_pc">
      <div class="login">
        <div class="login_designation">
          <img :src="'http://juqi.width168.com' + this.centerlog " alt="">
          <!-- <h4>医疗保险健康有限公司</h4>
       -->
        </div>

      </div>
      <div class="tabr">

        <ul class="mune">
          <li v-for="(item, index) in list" :key="index" @click="jump(item)" class="choose">{{ item.name }}</li>
        </ul>

      </div>
      <div class="head_iphone">
        <div
          class="NavigationMenu_YD_icon"
          @click="jumps"
          :class="quxiao ? '' : 'open'"
        >
          <span
            class="l_1"
            :style="styleshow == true ? '' : 'background : #fff'"
          ></span>
          <span
            class="l_2"
            :style="styleshow == true ? '' : 'background : #fff'"
          ></span>
          <span
            class="l_3"
            :style="styleshow == true ? '' : 'background : #fff'"
          ></span>
        </div>
    </div>
    </div>
 
    <el-drawer title="菜单" :visible.sync="drawer" :direction="direction" size="50%" :modal="false" :before-close="handleClose">
      <ul class="iphone_mune">
          <li v-for="(item, index) in list" :key="index" @click="jumpiphone(item)" class="choose">{{ item.name }}</li>
        </ul>
      </el-drawer>

  </div>
</template>

<script>
import { getlogo } from "@/api/index";
export default {
  data() {
    return {
      list: [
        {
          name: '首页',
          url: 'home',
          id: 1
        },
        {
          name: '关于我们',
          id: 1,
          url: 'Aboutus'
        }, {
          name: '产品展示',
          id: 1,
          url: 'Medicalsupplies'
        }, {
          name: '新闻中心',
          id: 1,
          url: 'news'
        }
        , {
          name: '在线反馈',
          id: 1,
          url: 'consultation'
        }
        , {
          name: '联系我们',
          id: 1,
          url: 'contactus'
        }
      ],
      lists: [{
        name: '首页',
        url: 'home',
        id: 1
      }],
      styleshow:true,
      drawer: false,
      quxiao:true,
      direction: 'rtl',
      centerlog:'',
    }
  },
  created(){ this.getinformation()},
  mounted(){
   
  },
  methods: {
    jump(item) {
      // console.log(item);
      this.lists = [{
        name: '首页',
        url: 'home',
        id: 1
      }]
      this.lists.push(item)
      if (item.name != '首页') {
        this.lists.push(item)
        let a = JSON.stringify(this.lists)
        console.log(a);
        sessionStorage.setItem('mun', a)
      }
      localStorage.setItem('Selectid', 0)
      this.$router.push({ name: item.url })
    },
    getinformation() {
      getlogo().then((res) => {
    this.centerlog = res.data.rows[0].logo_image
})
},
    jumpiphone(item){
      this.lists = [{
        name: '首页',
        url: 'home',
        id: 1
      }]
      if (item.name != '首页') {
        this.lists.push(item)
        let a = JSON.stringify(this.lists)
        console.log(a);
        sessionStorage.setItem('mun', a)
      }
      this.handleClose()
      this.$router.push({ name: item.url })
    },
    jumps(){
      this.quxiao=!this.quxiao
      this.drawer=true
    },
    handleClose(done) {
      this.quxiao=true
      this.drawer=false
      }
  }

}
</script>


<style scoped lang="scss">
.head {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 15;
  background: #fff;
  .head_pc {
  
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
  }

  .login {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    background: #fff;
    align-items: center
  }

  .login_designation {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    img{
      height: 80px;
    }
  }
  .tabr {
    width: 100%;
    height: 80px;

      background-color: #207fdb;
    // height: 100%;

    .mune {
      width: 50%;
      height: 100%;
      margin: 0 auto;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .choose {
        width: 12%;
        height: 100%;
        font-size: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .choose:hover {
        background-color: #45d741;
      }
    }
  }

}

  .head_iphone{
    width: 70px;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #207fdb;
    .NavigationMenu_YD_icon {
  width: 44px;
  height: 41px;
  cursor: pointer;
  position: relative;

  span {
    display: block;
    width: 24px;
    height: 2px;
    left: 10px;
    background-color: #fff;
    position: absolute;
    transition: all 0.25s linear;
  }

  span.l_1 {
    top: 10px;
  }

  span.l_2 {
    top: 18px;
  }

  span.l_3 {
    top: 26px;
  }
}
.NavigationMenu_YD_icon.open span.l_2 {
  opacity: 0;
}

.NavigationMenu_YD_icon.open span.l_1 {
  top: 20px !important;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.NavigationMenu_YD_icon.open span.l_3 {
  top: 20px !important;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.NavigationMenu_YD {
  position: fixed;
  top: 44px;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  z-index: 99;
  overflow-y: auto;
  animation-name: drop-menu;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @keyframes drop-menu {
    from {
      height: 0;
    }

    to {
      height: 100%;
    }
  }

  .NavigationMenu_YD_box {
    width: 100%;
    // background: #fff;
    background: linear-gradient(54deg, #75F8D2, #6096DB, #2AE3FC);
    padding-bottom: 50px;
    ul{
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
}



}
::v-deep .el-drawer__header{
  color: #FFF !important;
}
::v-deep .el-drawer.rtl{
  background-color: #207fdb;
}

.iphone_mune{
    .choose{
      text-align: center;
      color: #FFF;
    cursor: pointer;
    padding: 10px  0;
    font-size: 14px;
    height: 35px;
    line-height: 35px;
      border-bottom: 1px solid #fff;
    }
  }
</style>
