
import requests from "../utils/request";  //引入二次封装的axios
//获取导航
export const login = ()=>requests({url:'/api/Nav?sort=weigh&order=desc',method:'get'});
// 产品列表
export const product = (datas)=>requests({url:'/api/product',method:'get' ,params:{...datas}});
//产品详情
export const productdetails = (datas)=>requests({url:'/api/product/view',method:'get', params:{...datas}});
export const aboutdetails = (datas)=>requests({url:'/api/about?order=asc',method:'get', });//关于我们
export const gethome_model = (datas)=>requests({url:'/api/home_model',method:'get', });//首页
export const getcontact = (datas)=>requests({url:'/api/contact',method:'get', });//联系我们
export const getlogo = (datas)=>requests({url:'/api/setting',method:'get', });//logo
export const getnewlist = (datas)=>requests({url:'/api/news?sort=id&order=desc',method:'get', params:{...datas}});//新闻
export const getindustry = (datas)=>requests({url:'/api/industry?order=',method:'get', });//新闻行业
export const getonlinemessage = (datas)=>requests({url:'/api/onlinemessage/add',method:'post',params:{...datas} });//提交反馈
export const getmunnev = (datas)=>requests({url:'/api/Nav',method:'get',params:{...datas} });//提交反馈

