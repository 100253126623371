
import axios from "axios";
let requests = axios.create({
  baseURL: "http://www.jqnmzy.cn",
  // baseURL: "/api",
  timeout: 5000,
  withCredentials: true,
  headers:{
    'Content-Type': "application/json;charset=UTF-8",
  }
});
 

requests.interceptors.request.use((config) => {
  return config;
});

requests.interceptors.response.use(
  (res) => {
   
    return res.data;
  },
  (err) => {
  
    console.log(err);
 
  }
);
export default requests;