<template>
  <div id="app">
    <headtabr></headtabr>
    <router-view/>
    <footers></footers>
  </div>
</template>
<script>
import headtabr from '@/components/headtabr.vue'
import footers from '@/components/footer.vue'
 export default {
  name: 'HomeView',
  components: {
    headtabr,footers
  },
  mounted(){
       
        }
 }
</script>

<style lang="scss">
body ,#app{
  margin: 0 ;
  padding: 0;
}
ul, li ,h4,p{
  list-style: none;
  margin: 0;
  padding: 0;

}
</style>
