<template>
    <div class="banser">
        <div class="block">

            <el-carousel height="480px">
                <el-carousel-item v-for="item in imglistL" :key="item.id" :interval="8000">
                    <div class="animate__animated animate__bounce animate__fadeInDownBig carousel_span">
                        <h4>{{item.model_content_main1}}

                        </h4>
                        <!-- <span>
                            {{item.model_content_f1}}
                        </span>
                        <span>{{item.model_content_f2}}</span>
                        <span>{{item.model_content_f3}}</span> -->
                    </div>
                    <img :src="'http://juqi.width168.com' + item.model_images" class="carousel_img" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="blockiphone">
            <el-carousel height="150px">
                <el-carousel-item v-for="item in imglistL" :key="item.id"  :interval="8000">
                    <img :src="'http://juqi.width168.com' + item.model_images" class="carousel_img" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>
<script>
import { gethome_model } from "@/api/index";
export default {
    data() {
        return {
            imglistL: [{
                id: 1,
                model_images: require('../../../assets/img/banner1.png')
            },
            {
                id: 2,
                model_images: 'https://medical-1135507-pic6.websiteonline.cn/upload/banner02.jpg'
            },
            {
                id: 3,
                model_images: 'https://medical-1135507-pic6.websiteonline.cn/upload/banner02.jpg'
            }
            ]
        }
    },
    created() {
        this.taberget()
    },
    computed: {
    },
    methods: {
        taberget() {

            gethome_model().then((res) => {
                this.imglistL = []
                this.imglistL.push(res.data.rows[7])
                this.imglistL.push(res.data.rows[6])

            })

        },
    },
}
</script>
<style lang="scss" scoped>
.blockiphone {
    display: none;
}

.carousel_img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.carousel_span {
    position: absolute;
    top: 30%;
    width: 100%;
    // height: 100%;
    text-align: center;


    h4 {
        font-family: MicrosoftYaHei;
        font-size: 60px;
        letter-spacing: 1px;
        color: rgb(255, 255, 255);
        line-height: 180%;
    }

    span {
        font-family: MicrosoftYaHei;
        color: rgb(255, 255, 255);
        font-size: 20px;
        line-height: 180%;
    }

}

.el-carousel-item {
    overflow-y: hidden;
}

.carousel_img {
    width: 100%;
    // height: auto;
}
</style>
