<template>
    <div class="open_account">
        <el-dialog title="温馨提示" top="30vh" z-index="9999"  :append-to-body="true" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" center>
            <span>{{value1}}</span>
            
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleClose">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ['open_accountlogin',"center"],
    data() {
        return {
            dialogVisible: false,
            result: "",
            value1: '本页面药品和产品信息，仅为向合作伙伴或医学专业人士作产品介绍所用并非产品广告。本网站信息不得取代医生或其他有资质的医疗专业人士提供的医疗建议和医疗咨询，不得用于疾病的诊断。如您需了解相关药品和产品的详细资料，请以其最新版说明书为准。如您已了解并确认前述内容的，请点击确定后进入。',
        };
    },
    mounted() {

    },
    methods: {
        handleClose(done) {
            sessionStorage.setItem("dialogVisible",1)
            this.dialogVisible = false
            this.$emit('passfunction', this.dialogVisible)
        },
        
       
    },
    watch: {
        open_accountlogin(val) {
            this.dialogVisible = val
            console.log(val);
        
        },
        center(val){
            this.value1 =val
        }
    }
};
</script>
<style lang="scss">
::v-deep .el-dialog__wrapper{
    z-index: 9999 !important;
}
.getAccountInfo1 {
    margin: 20px;
}
.el-dialog{
    border-radius:20px ;
}
.el-dialog__body{
    padding: 10px 20px !important;
}
</style>
