import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Medicalsupplies',
    name: 'Medicalsupplies',

    component: () => import( '../views/Medicalsupplies/Medicalsupplies.vue')
  },
  {
    path: '/news',
    name: 'news',

    component: () => import( '../views/news/news.vue')
  },
  {
    path: '/consultation',
    name: 'consultation',

    component: () => import( '../views/consultation/consultation.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',

    component: () => import( '../views/contactus/contactus.vue')
  },
  {
    path: '/Aboutus',
    name: 'Aboutus',

    component: () => import( '../views/Aboutus/Aboutus.vue')
  },
  {
    path: '/Newsdetails',
    name: 'Newsdetails',

    component: () => import( '../views/Newsdetails/Newsdetails.vue')
  },
  {
    path: '/Commodity',
    name: 'Commodity',

    component: () => import( '../views/Commodity/Commodity.vue')
  },
  
]
const originalPush = VueRouter.prototype.push;
 
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {    
  // 兼容chrome
  document.body.scrollTop = 0
  // 兼容firefox
  document.documentElement.scrollTop = 0
  // 兼容safari
  window.pageYOffset = 0
  next()
})


export default router
