<template>
  <div class="home">
    <slideshow></slideshow>
    <center_home></center_home>
    <daielog :open_accountlogin="open_accountlogins" @passfunction="passfunctions" :center="center"></daielog>
  </div>
</template>
<script>
import slideshow from './components/banser.vue'
import center_home from './components/center_home.vue'
import daielog from '@/components/daielog.vue'

import { gethome_model, product ,getcontact} from "@/api/index";
export default {
  components: { slideshow, center_home, daielog },
  data() {
    return {
      open_accountlogins: false,
      center:''

    }
  },
  created() {
    // this.jump()
  },
  mounted() {
    // this.taberget()
  },
  methods: {
    taberget() {

      gethome_model().then((res) => {
        console.log(res);
      })

      var list = { pageIndex: 1, pageSize: 10, }
      // gettabr(list).then((res)=>{
      //   console.log(res);
      // })

    },
    passfunctions(data) {
      this.open_accountlogins = data
    },
    jump() {
      let type = sessionStorage.getItem('dialogVisible') || ""
      getcontact().then((res)=>{
        this.center=res.data.rows[0].company_memo_content
      })
      if (type == 1) {
        this.open_accountlogins = false

      } else {
        this.open_accountlogins = true
      }

    }
  },
}
</script>
<style></style>

