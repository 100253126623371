import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Select,Carousel,CarouselItem,Pagination,Form,FormItem,Input,Option,MessageBox,Message,Drawer,Dialog,Tabs,TabPane,Notification,Upload,Image } from 'element-ui';
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'animate.css';
// 导入summernote编辑器
import 'summernote'
// 导入summernote编辑器css样式
import 'summernote/dist/summernote.css'
// 导入summernote编辑器中文语言包
// 导入bootstrap
// 导入bootstrapCss样式
// 导入popper.js，popper.js是bootstrap下的一个特效
// import 'popper.js'

Vue.use(VueAxios, axios)


Vue.config.productionTip = false
Vue.use(Button).use(Pagination).use(Select).use(CarouselItem).use(Carousel).use(FormItem).use(Form).use(Input).use(Option).use(Drawer).use(Dialog).use(Tabs).use(TabPane).use(Upload).use(Image)
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$notify = Notification;
// 响应式
import "@/assets/css/max2000.css"
import "@/assets/css/max1920.css"
import "@/assets/css/max1800.css"
import "@/assets/css/max1680.css"
import "@/assets/css/max1550.css"
import "@/assets/css/max1366.css"
import "@/assets/css/max1260.css"
import "@/assets/css/max1024.css"
import "@/assets/css/max992.css"
import "@/assets/css/max750.css"
import "@/assets/css/max479.css"


window._AMapSecurityConfig = {
	securityJsCode: 'e82e5b886cf1eac20ac16f69b44b6bde' //*  安全密钥
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
