<template>
    <div class="footer_box">
        <div class="footer">
            <div class="footer_center">
                <div class="footer_left">
                    <h4>联系我们</h4>
                    <div class="address">
                        <i class="el-icon-location-outline"></i>
                        <p>{{ center.company_address }}</p>
                    </div>
                    <div class="address">
                        <i class="el-icon-message"></i>
                        <p>{{ center.company_hottel }}</p>
                    </div>
                    <div >
                        <div class="address">
                            <i class="el-icon-phone-outline"></i>
                        <p>{{ center.company_tel }}</p>
                        </div>
                      <div class="address">
                        <i class="el-icon-phone-outline"></i>
                        <p>{{ center.company_tax }}</p>
                      </div>
                    
                    </div>

                    <!-- <div class="Two-dimensional">
                        <img class="code" :src="'http://juqi.width168.com' + center.company_wx_image" alt="">
                        <span>扫一扫，关注我们</span>
                    </div> -->
                </div>
                <div class="footer_right">
                    <div class="Quick_link">
                        <h4>快速链接</h4>
                        <ul>
                            <li v-for="(item, index) in list "  @click="jump(item)" :key="index">
                                <span>{{ item.name }}</span>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
            <div class="footer_Copyright">
              {{center.filings_content || '© 重庆巨琪诺美制药有限公司,' }}  
              <a href="https://beian.miit.gov.cn" target="_blank">渝ICP备18003728号</a>
            </div>
        </div>
        <div class="iphone_footer">
            <!-- <div class="footer_search">
                <el-input placeholder="请输入内容" v-model="input2">
                    <template slot="append"><el-button slot="append" icon="el-icon-search"></el-button></template>
                </el-input>
            </div> -->
            <div class="fullcontent_opacity">
                <div class="dimensional">
                    <img class="code"
                    :src="'http://juqi.width168.com' + center.company_wx_image"
                        alt="">
                    <p>扫一扫，关注我们</p>
                </div>
                <div class="Quick_link">

                    <ul>
                        <li v-for="(item, index) in list " @click="jump(item)" :key="index">
                            <span>{{ item.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getcontact } from "@/api/index";
export default {
    data() {
        return {
            list: [
                {
                    name: '首页',
                    url: 'home',
                    id: 1
                },
                {
                    name: '关于我们',
                    id: 1,
                    url: 'Aboutus'
                }, {
                    name: '产品展示',
                    id: 1,
                    url: 'Medicalsupplies'
                }, {
                    name: '新闻中心',
                    id: 1,
                    url: 'news'
                }
                , {
                    name: '在线反馈',
                    id: 1,
                    url: 'consultation'
                }
                , {
                    name: '联系我们',
                    id: 1,
                    url: 'contactus'
                }
            ],
            input2: '',
            center: {},
            lists: [{
                name: '首页',
                url: 'home',
                id: 1
            }],
        }
    },
    created() {
        this.getinformation()
    },
    computed: {
    },
    methods: {
        getinformation() {

            getcontact().then((res) => {
                this.center = res.data.rows[0]
                localStorage.setItem('cnenter', this.center.company_memo_content)
            })
        },
        jump(item) {
      console.log(item);
      this.lists = [{
        name: '首页',
        url: 'home',
        id: 1
      }]
      this.lists.push(item)
      if (item.name != '首页') {
        this.lists.push(item)
        let a = JSON.stringify(this.lists)
        console.log(a);
        sessionStorage.setItem('mun', a)
      }

      this.$router.push({ name: item.url })
    },
    },
}
</script>
<style lang="scss" scoped>
// .footer_box{
//     position: absolute;
//     width: 100%;
//     bottom: 0;
// }
.footer {
    width: 100%;
    // height: 295px;
    background-color: rgb(29, 51, 74);
    background-position: center center;
    position: relative;

    .footer_center {
        width: 50%;
        margin: 0 auto;
        display: flex;
        // align-items: center;


        .footer_left {
            background-color: rgb(32, 127, 221);
            width: 268px;
            height: 100%;
            padding: 50px 40px;
            // border-style: solid;
            background-position: center center;
            z-index: 12;

            h4 {
                font-size: 18px;
                font-weight: 500;

                color: rgb(255, 255, 255);
                height: 41px;
                margin-bottom: 10px;
            }

            .address {
                display: flex;
                align-items: center;
                margin-bottom: 30px;

                p {
                    margin-left: 10px;
                    font-size: 12px;
                    color: rgb(208, 232, 255);
                }
            }

            .Two-dimensional {
                display: flex;
                // justify-content: center;
                align-items: center;

                .code {
                    width: 85px;
                    height: 85px;
                }

                span {
                    margin-left: 10px;
                    font-size: 12px;
                    color: rgb(208, 232, 255);
                }

            }
        }

        .footer_right {
            padding: 50px 40px;

            .Quick_link {
                margin-left: 120px;

                h4 {
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    margin-bottom: 30px;
                }

                ul {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    li {
                        width: 90px;
                        height: 45px;
                        color: #8ca5bb;
                        font-size: 12px;
                        cursor: pointer;
                    }


                }
            }
        }


    }

    .footer_Copyright {
        position: absolute;
        height: 81px;
        width: 100%;
        background-color: rgb(19, 35, 51);
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(140, 165, 187);
        font-size: 12px;
        a{
            color: rgb(140, 165, 187);
        font-size: 12px;
        text-decoration-line: none
        }

    }
    .footer_Copyright a:hover{
        text-decoration-line:revert !important
    }
}

.iphone_footer {
    display: none;
}

.footer_search {
    padding: 0px 20px;
    border-color: transparent;
    border-width: 0px;
    height: 78px;
    background-color: rgb(32, 127, 221);
    display: flex;
    align-items: center;
}

.fullcontent_opacity {
    padding: 30px;
    // height: 295px;
    background-color: rgb(29, 51, 74);
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dimensional {
        text-align: center;

        .code {
            width: 90px;
            height: 90px;
        }

        p {
            font-size: 12px;
            font-family: 微软雅黑;
            color: rgb(140, 165, 187);
        }
    }

    .Quick_link {


        h4 {
            font-size: 18px;
            color: rgb(255, 255, 255);
            margin-bottom: 30px;
        }

        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            li {
                width: 80px;
                height: 35px;
                font-size: 12px;
                color: rgb(255, 255, 255);
                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
            }


        }
    }
}

.el-input,
.el-button {
    border-radius: 0px !important;

    .el-input__inner,
    .el-input-group__append {
        border-radius: 0px !important;
    }
}
</style>
