<template>
    <div class="center_home">
        <!-- <div class="wp-media_content">
            <ul>
                <li v-for="(item, index) in list" :key="index">
                    <div>
                        <div class="animate__animated animate__bounce animate__flipInY mediabox">
                            <img :src="item.url" alt="">
                        </div>
                        <div class="animate__animated animate__bounce animate__fadeInLeftBig ">
                            <span>{{ item.namme }}</span>  
                            
                            <p>{{ item.vaule }}</p>
                            <p>{{ item.vaule2 }}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div> -->
        <div class="about">
            <span class="about_span">{{ homedatas[2].model_content_main1 }}</span>
            <p class="about_p">{{ homedatas[2].model_content_main2 }}</p>
            <div class="wp-box_content drop_box">
                <img class="wp-media-image" :src="homedatas[2].model_images" alt="">
                <div class="center">
                    <div class='animate__animated animate__bounce animate__fadeInDown' style="margin-bottom: 10px;">
                        <p>{{ homedatas[2].model_content_f1 }}</p>
                        <p>{{ homedatas[2].model_content_f2 }}</p>
                        <p>{{ homedatas[2].model_content_f3 }}</p>
                    </div>
                    <div class="animate__animated animate__bounce animate__flipInX">
                        <el-button type="success" class="READMORE" @click="ghetmore(3)">{{ homedatas[2].model_button_name
                        }}</el-button>
                    </div>

                </div>
            </div>
        </div>
        <div class="cstlayer">
            <div class="wp_title">
                <span>{{ homedatas[1].model_content_main1 }}</span>
                <h4>{{ homedatas[1].model_content_main2 }}</h4>
            </div>
            <div class="cstlayer_center">
                <ul>
                    <li v-for="(items, indexs) in list2" :key="indexs" @click="jump(items)">
                        <div class="img_lazy_box">
                            <div class="img_lazyi">{{ items.name }} </div>
                            <img class="img_lazy_load" :src="'http://juqi.width168.com' + items.images[0]" alt="">
                        </div>
                        <div class="wp-new-article-style-c">
                            <span class="cstlayer_span">{{ items.name }}</span>
                            <span class="cstlayer_vualke">{{ items.characteristic }}</span>
                            <!-- <p class="cstlayer_p">{{ items.weigh }}</p> -->
                        </div>

                    </li>
                </ul>
            </div>

            <div class="cstlayer_button"
                :class="scrollTop >= 480 ? 'animate__animated animate__bounce animate__flipInX ' : ''">
                <el-button type="success" @click="ghetmore(2)">{{ homedatas[1].model_button_name }}</el-button>
            </div>
        </div>
        <div class="Press_center">
            <div class="wp_title">
                <span>{{ homedatas[0].model_content_main1 }}</span>
                <h4>{{ homedatas[0].model_content_main2 }}</h4>
            </div>
            <div class="Press_list">
                <ul>
                    <li v-for="(i, inif) in list3" :key="inif">
                        <img class="wp_drop_img" v-if="(i.id == 1)" :src="i.url" alt=""
                            :class="scrollTop >= 980 ? 'animate__animated animate__bounce animate__fadeInLeft ' : ''">
                        <div class="wp_drop_box">
                            <span class="wp_drop_span" @click="newjump(i)">{{ i.title }}</span>
                            <div class="wp_drop_time">{{ i.time }}</div>
                            <p>{{ i.abstract }}</p>
                        </div>
                        <img class="wp_drop_img" v-if="(i.id == 2)" :src="i.url" alt=""
                            :class="scrollTop >= 980 ? 'animate__animated animate__bounce animate__fadeInRight ' : ''">
                    </li>
                </ul>
                <div class="Press_button"
                    :class="scrollTop >= 1080 ? 'animate__animated animate__bounce animate__flipInX ' : ''">
                    <el-button type="success" @click="ghetmore(1)">{{ homedatas[0].model_button_name }}</el-button>
                </div>
            </div>
            <div class="cstlayer_iphone_center">
                <div>
                    <img class="img_lazy_load" :src="list3[0].img" alt="" />
                </div>
                <ul>
                    <li v-for="(i, inif) in list3" :key="inif" class="iphonewp_drop_li">
                        <div class="iphonewp_drop_box" @click="newjump(i)">
                            <span class="iphonewp_drop_span">{{ i.title }}</span>
                            <!-- <div class="iphonewp_drop_time">{{ i.createtime }}</div> -->
                            <p>{{ i.abstract }}</p>
                        </div>

                    </li>
                </ul>
                <div class="Press_button">
                    <el-button type="success" @click="ghetmore(1)">{{ homedatas[0].model_button_name }}</el-button>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import { product, gethome_model, getnewlist } from "@/api/index";
export default {
    data() {
        return {
            list: [
                {
                    id: 17,
                    model_name: "bigicon3",
                    model_content_main1: "专业的医疗顾问",
                    model_content_main2: null,
                    model_content_f1: "我们配备了专业的管理人员，",
                    model_content_f2: "在全国更有队伍庞大的技术人员",
                    model_content_f3: "",
                    model_mobile_content: "",
                    model_button_name: null,
                    model_files: "",
                    model_images: "/uploads/20230613/c53337590f8ddf09b856f00949a1b46b.png",
                },
                {
                    id: 17,
                    model_name: "bigicon3",
                    model_content_main1: "专业的医疗顾问",
                    model_content_main2: null,
                    model_content_f1: "我们配备了专业的管理人员，",
                    model_content_f2: "在全国更有队伍庞大的技术人员",
                    model_content_f3: "",
                    model_mobile_content: "",
                    model_button_name: null,
                    model_files: "",
                    model_images: "/uploads/20230613/c53337590f8ddf09b856f00949a1b46b.png",
                },
                {
                    id: 17,
                    model_name: "bigicon3",
                    model_content_main1: "专业的医疗顾问",
                    model_content_main2: null,
                    model_content_f1: "我们配备了专业的管理人员，",
                    model_content_f2: "在全国更有队伍庞大的技术人员",
                    model_content_f3: "",
                    model_mobile_content: "",
                    model_button_name: null,
                    model_files: "",
                    model_images: "/uploads/20230613/c53337590f8ddf09b856f00949a1b46b.png",
                }
            ],
            list2: [
            ],
            list3: [
                {
                    id: 1,
                    title: '',
                    abstract: '',
                    createtime: '',
                    url: 'https://medical-1135507-pic6.websiteonline.cn/upload/n_01.jpg',
                    news_content: ''
                },
                {
                    id: 2,
                    title: '',
                    abstract: '',
                    createtime: '',
                    url: 'https://medical-1135507-pic6.websiteonline.cn/upload/n_01.jpg',
                    news_content: ''
                },
            ],
            scrollTop: '',
            homedatas: []
        }

    },
    created() {
        this.gethome_center()
        this.gethome_new();
        this.Productlist();
    },
    computed: {
    },

    mounted() {
        window.addEventListener("scroll", this.scrolling);
    },
    methods: {
        jump(items) {
            var lists = [{
                name: '首页',
                url: 'home',
                id: 1
            }]
            lists.push(items)

            sessionStorage.setItem('mun', JSON.stringify(lists))
            this.$router.push({ name: 'Commodity', query: { id: items.id } })
        },
        scrolling() {
            // 滚动条距文档顶部的距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body
                .scrollTop // 滚动条偏移量
            this.scrollTop = scrollTop
        },
        ghetmore(id) {
            switch (id) {
                case 1:
                    this.$router.push({ name: "news" })

                    break;
                case 2:
                    this.$router.push({ name: "Medicalsupplies" })

                    break;
                case 3:
                    this.$router.push({ name: "Aboutus" })

                    break;


                default:
                    break;
            }
        },
        Productlist() {
            var tarlist = {
                sort: 'weigh',
                order: 'desc',
                limit: 8
            }
            product(tarlist).then((res) => {
                this.list2 = res.data.rows
            })
        },
        gethome_center() {
            gethome_model().then((res) => {
                this.homedatas = res.data.rows
                this.list = []
                this.list.push(this.homedatas[5])
                this.list.push(this.homedatas[4])
                this.list.push(this.homedatas[3])
            })
        },
        newjump(item) {
            console.log(item);
            this.$router.push({ name: "Newsdetails", params: item })
        },
        gethome_new() {
            var tarlist = {
                sort: 'weigh',
                order: 'desc',
                limit: 2
            }
            getnewlist(tarlist).then((res) => {
                this.homedatas = res.data.rows
                this.list3[0].title = this.homedatas[0].title
                this.list3[0].abstract = this.homedatas[0].abstract
                this.list3[0].news_content = this.homedatas[0].news_content
                this.list3[1].title = this.homedatas[1].title
                this.list3[1].abstract = this.homedatas[1].abstract
                this.list3[1].news_content = this.homedatas[1].news_content
                this.list3[1].createtime = this.formatDate(this.homedatas[1].createtime)
                this.list3[0].createtime = this.formatDate(this.homedatas[0].createtime)
            })
        },
        formatDate(value) {
            if (value == null) {
                return ''
            } else {
                var dateTime = new Date(parseInt(value) * 1000)
                var year = dateTime.getFullYear();
                var month = dateTime.getMonth() + 1;
                var day = dateTime.getDate();
                var hour = dateTime.getHours();
                var minute = dateTime.getMinutes();
                var second = dateTime.getSeconds();
                var now = new Date();
                var now_new = Date.parse(now.toDateString());
                var milliseconds = now_new - dateTime;
                var timeSpanStr = year + '-' + month + '-' + day;
                return timeSpanStr;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.center_home {
    position: relative;

    .wp-media_content {

        height: 220px;
        background-color: rgb(245, 245, 245);
        display: flex;
        align-items: center;

        ul {
            width: 80%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            li {
                // overflow-wrap: break-word;
                padding: 0px;
                border-color: transparent;
                border-width: 0px;
                height: 220px;
                // background-color: rgb(239, 239, 239);
                width: 400px;
                border-style: solid;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                .mediabox {
                    position: relative;
                    display: flex;
                    justify-content: center;
                }

                img {
                    width: 75px;
                    height: 58px;
                }

                span {
                    font-family: MicrosoftYaHei;
                    color: rgb(51, 51, 51);
                    font-size: 18px;
                    line-height: 220%;
                }

                p {
                    font-size: 14px;
                    color: rgb(153, 153, 153);
                    line-height: 180%;
                }
            }

        }
    }

    .wp-media_content ul li:nth-child(2) {
        overflow-wrap: break-word;
        padding: 0px;
        border-color: transparent;
        border-width: 0px;
        height: 220px;
        background-color: rgb(239, 239, 239);
        width: 400px;
        border-style: solid;
        // border-radius: 10px;
    }

    // about
    .about {
        padding-top: 80px;
        text-align: center;
        width: 65%;
        margin: 0 auto;

        .about_span {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 40px;
            color: rgb(204, 204, 204);
            line-height: 110%;
        }

        p {
            font-size: 26px;
            color: rgb(49, 49, 49);
            line-height: 110%;
        }

        .wp-box_content {
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
            height: 388px;
            margin-top: 170px;
            background-color: #ffff;
            // border-radius:10px ;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 6;
            padding: 10px 10px 80px 10px;


            p {
                font-size: 14px;
                color: rgb(136, 136, 136);
                line-height: 250%;
            }

            .center {
                position: absolute;
                bottom: 0;
                margin-bottom: 80px;




            }

            .wp-media-image {
                position: absolute;
                top: 0;
                margin-top: -100px;
                // width: 80%;
            }

        }
    }

    // 
    .cstlayer {
        // position: absolute;

        margin-top: -80px !important;
        z-index: 1;
        height: 100%;
        background-color: rgb(32, 127, 221);
        background-position: center center;

        .wp_title {
            text-align: center;
            padding-top: 150px;
            margin-bottom: 80px;


            span {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 40px;
                color: rgb(255, 255, 255);
                line-height: 110%;
            }

            h4 {
                font-family: "MicrosoftYaHei";
                font-size: 26px;
                color: rgb(255, 255, 255);
                line-height: 110%;
            }
        }

        .cstlayer_center {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;

            ul {
                // width: 50%;
                display: grid;
                column-gap: 28px;

                row-gap: 32px;
                grid-template-columns: repeat(4, 1fr);

                li {
                    width: 279px;
                    background: #fff;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 10px;
                    .img_lazy_box {
                        position: relative;

                        .img_lazyi {
                            opacity: 0;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: rgba(113, 233, 111, 0.8);
                            text-align: center;
                            font-size: 16px;
                            color: #FFFFFF;
                            font-weight: bold !important;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                        }
                    }

                    .img_lazy_load {
                        height: 200px;
                        width: 100%;
                        text-align: center;
                        vertical-align: middle;
                        display: table-cell;
                    }

                    .img_lazy_box:hover .img_lazyi {
                        opacity: 1 !important;

                    }

                    .wp-new-article-style-c {
                        padding-bottom: 10px;
                        height: 64px;
                    }

                    .cstlayer_span {
                        font-family: Microsoft YaHei;
                        font-size: 16px;
                        color: #333333;
                        font-weight: bold !important;
                        display: block;
                        text-align: center;
                    }

                    .cstlayer_vualke {
                        font-family: Microsoft YaHei;
                        font-size: 14px;
                        color: #333333b5;
                        font-weight: bold !important;
                        display: block;
                        text-align: center;
                    }

                    .cstlayer_p {}

                    font-family: Microsoft YaHei;
                    font-size: 16px;
                    color: #45d741;
                    font-weight: bold;
                    line-height: 140%;
                }
            }
        }

        .READMORE {
            position: absolute;
            bottom: 0;
        }

        .cstlayer_button {
            padding: 60px 0;
            display: flex;
            justify-content: center;
        }
    }

    // 
    .Press_center {
        .wp_title {
            text-align: center;
            margin: 80px 0;

            span {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 40px;
                color: rgb(204, 204, 204);
                line-height: 110%;
            }

            h4 {
                font-family: "MicrosoftYaHei";
                font-size: 26px;
                color: rgb(49, 49, 49);
                line-height: 110%;
            }
        }

        .Press_list {
            width: 60%;
            margin: 0 auto;

            li {
                display: flex;
                // justify-content: center;
                align-items: center;
                margin-bottom: 10px;
                background-color: rgb(249, 249, 249);

                .wp_drop_img {
                    width: 530px;
                    height: 205px;
                }

                .wp_drop_box {
                    height: 155px;
                    padding: 25px;
                    // background-color: rgb(249, 249, 249);

                    .wp_drop_span {
                        font-family: Microsoft YaHei;
                        font-size: 15px;
                        color: #5A5A5A;
                        font-weight: bold;
                        text-decoration: none;
                        line-height: 140%;
                        cursor: pointer;
                    }

                    .wp_drop_time {
                        font-family: Microsoft YaHei;
                        font-size: 14px;
                        padding-top: 10px;
                        color: #b8b8b8;
                        font-weight: normal;
                        text-decoration: none;
                    }

                    p {
                        font-family: Microsoft YaHei;
                        font-size: 14px;
                        padding-top: 8px;
                        color: #888888;
                        font-weight: normal;
                    }
                }


            }

            .Press_button {
                margin: 60px 0;
                display: flex;
                justify-content: center;
            }
        }

    }

    .cstlayer_iphone_center {
        display: none;

        .iphonewp_drop_li {
            padding: 10px 20px;


            .iphonewp_drop_box {
                border-bottom: 1px dotted #e2e2e2;

                .iphonewp_drop_span {
                    font-size: 14px;
                    color: #5a5a5a !important;
                    font-weight: bold;

                }

                .iphonewp_drop_time,
                p {
                    margin: 10px 0;
                    display: block;
                    font-family: Microsoft YaHei;
                    font-size: 12px;
                    color: #999999;
                    font-weight: normal;
                    text-decoration: none;
                    line-height: 140%;
                }

            }

        }

        .Press_button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 0;
        }


    }
}</style>
